import { PrismicLink } from "@prismicio/react"
import clsx from "clsx"
import { type IGatsbyImageData } from "gatsby-plugin-image"
import { HoverZoomImage } from "./HoverZoomImage"
import { Text } from "./Text"

const variants = {
	red: {
		bg: "bg-red",
		decorationColor: "bg-gray-10",
	},
	black: {
		bg: "bg-gray-10",
		decorationColor: "bg-red",
	},
}

interface HoverCardProps extends React.ComponentPropsWithoutRef<"a"> {
	image?: IGatsbyImageData
	imageAlt?: string
	title: string
	href: string
	variant?: keyof typeof variants
	renderImage?: React.ReactElement
}

export const HoverCard = ({
	image,
	imageAlt,
	href,
	title,
	className,
	variant = "black",
	renderImage,
	...props
}: HoverCardProps) => {
	const styles = variants[variant]
	const titleWords = title?.split(" ") ?? []

	return (
		<PrismicLink
			href={href}
			className={clsx(className, "relative block bg-gray-10 group")}
			{...props}
		>
			<div className="aspect-[272/215]">
				{(image || renderImage) && (
					<HoverZoomImage
						image={image}
						alt={imageAlt}
						renderImage={renderImage}
						className="bg-gray-93"
					/>
				)}
			</div>

			<div
				className={clsx(
					styles.bg,
					"absolute",
					"-left-[26px] xl:-left-[54px]",
					"top-[50%] translate-y-[-50%]",
					"py-6",
					"px-6 lg:px-[18px]",
					"text-white"
				)}
			>
				<div className="grid justify-items-start gap-y-4 min-w-[6rem] lg:min-w-[10rem]">
					{titleWords.map((word, idx) => {
						const isLastWord = idx === titleWords.length - 1

						return (
							<Text
								variant="heading3"
								className={clsx(isLastWord && "relative pb-2.5 lg:pb-3")}
								key={idx}
							>
								{word}

								{isLastWord && (
									<div
										className={clsx(
											styles.decorationColor,
											"w-4 lg:w-5",
											"h-1 lg:h-1.5",
											"absolute",
											"left-0",
											"top-full",
											"origin-left",
											"transition-[width] duration-[400ms] ease-in-out",
											"group-hover:w-full"
										)}
									/>
								)}
							</Text>
						)
					})}
				</div>
			</div>
		</PrismicLink>
	)
}
